import React from 'react'
import { LinearProgress } from '@mui/material'
import { useOrderStatusData } from '../../lib/global/orderstatus_hook'
import { setOrderPaid } from '../../lib/orders_hook'
import BusinessIcon from '@mui/icons-material/Business'
import PaymentIcon from '@mui/icons-material/Payment'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'

export interface OrderLiveDataProps {
  orderId: number
  mailAddress: string
  order: {
    paid: boolean
    hash: string
  }
}

export interface OrderStatusData {
  paid: boolean
  transfer: string
  status_p: string
  status_s: string
  post: string
}

export default function OrderLiveData(props: OrderLiveDataProps) {
  const [disabled, setDisabled] = React.useState(false)

  const orderStatusData: OrderStatusData | string | undefined = useOrderStatusData({
    orderId: props.orderId,
    mailAddress: props.mailAddress,
  })

  if (orderStatusData === undefined) {
    return (
      <div className="row">
        <div className="col-md-12">
          <LinearProgress />
        </div>
      </div>
    )
  } else if (typeof orderStatusData === 'string') {
    return (
      <div className="row">
        <div className="col-md-12">
          <span>{orderStatusData}</span>
        </div>
      </div>
    )
  } else {
    if (orderStatusData.paid && !props.order.paid) {
      setOrderPaid(props.order.hash)
    }

    return (
      <div className="row">
        <div className="col-md-12">
          {orderStatusData.transfer === '' && orderStatusData.status_p != '' && (
            <>
              <span>
                <PaymentIcon /> {orderStatusData.status_p}
              </span>
              <br />
            </>
          )}
          {orderStatusData.status_s != '' && (
            <>
              <span>
                <BusinessIcon /> {orderStatusData.status_s}
              </span>
              <br />
            </>
          )}
          {orderStatusData.post !== '' && (
            <span>
              <LocalShippingIcon /> {orderStatusData.post}
            </span>
          )}
          {orderStatusData.post === '' && orderStatusData.transfer !== '' && (
            <span>
              <HourglassEmptyIcon /> {orderStatusData.transfer}
            </span>
          )}
        </div>
      </div>
    )
  }
}
